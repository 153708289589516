<template>
  <span>
    <v-data-table class="table-indicadores" multi-sort dense :headers="headers" :items="items"
      :options="{ itemsPerPage: 10 }" :headerProps="{ sortByText: 'Ordenar Por' }"
      no-data-text="Nenhum registro disponível" no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10">
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text }}</span>
          </template>
          <span v-html="traducaoIndicadores[h.value]"></span>
        </v-tooltip>
        <span v-else>{{ h.text }}</span>
      </template>
      <template v-slot:[`item.empresa`]="{ item }">
        {{ item.empresa.name | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.pendentes`]="{ item }">
        <span :class="classIndicador(item.pendentes, item.metas.pendentes != undefined ? item.metas.pendentes : null)">
          {{ item.pendentes | parseNumberToFloatBrIgnoringNaN }}
        </span>

      </template>
      <template v-slot:[`item.pendentes_em_atraso`]="{ item }">
        <span
          :class="classIndicador(item.pendentes_em_atraso, item.metas.pendentes_em_atraso != undefined ? item.metas.pendentes_em_atraso : null)">
          {{ item.pendentes_em_atraso | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.pat`]="{ item }">
        <span :class="classIndicador(item.pat, item.metas.pat != undefined ? item.metas.pat : null)">
          {{ item.pat | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.concluidos`]="{ item }">
        <span
          :class="classIndicador(item.concluidos, item.metas.concluidos != undefined ? item.metas.concluidos : null)">
          {{ item.concluidos | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.concluidos_fora_prazo`]="{ item }">
        <span
          :class="classIndicador(item.concluidos_fora_prazo, item.metas.concluidos_fora_prazo != undefined ? item.metas.concluidos_fora_prazo : null)">
          {{ item.concluidos_fora_prazo | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.patc`]="{ item }">
        <span :class="classIndicador(item.patc, item.metas.patc != undefined ? item.metas.patc : null)">
          {{ item.patc | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.compensacao`]="{ item }">
        <span
          :class="classIndicador(item.compensacao, item.metas.compensacao != undefined ? item.metas.compensacao : null)">
          {{ item.compensacao | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.psa`]="{ item }">
        <span
          :class="classIndicador(item.psa, item.metas.psa != undefined ? item.metas.psa : null)">
          {{ item.psa | parseNumberToFloatBrIgnoringNaN }} %
        </span>
      </template>
      <template v-slot:[`item.psfp`]="{ item }">
        <span
          :class="classIndicador(item.psfp, item.metas.psfp != undefined ? item.metas.psfp : null)">
          {{ item.psfp | parseNumberToFloatBrIgnoringNaN }} %
        </span>
      </template>
      <template v-slot:[`item.metas`]="{ item }">
        <v-btn @click="openMetasDialog(item)" min-width="0" class="px-1" fab icon x-small>
          <v-icon small>
            mdi-information-outline
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>


    <v-dialog v-model="metasDialog" max-width="720">
      <v-card>
        <v-card-title class="text-h5">
          <h4> {{ itemSelected.empresa.name }} - {{ itemSelected.competencia }} </h4>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="table-indicadores" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Valor</th>
                  <th>Meta</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="indicador in indicadores" :key="indicador.value">
                  <v-tooltip bottom v-if="traducaoIndicadores[indicador.value] != null">
                    <template v-slot:activator="{ on }">
                      <td v-on="on">

                        {{ indicador.text | toUpperCase }}
                      </td>
                    </template>
                    <span v-html="traducaoIndicadores[indicador.value] "></span>
                  </v-tooltip>
                  <td v-else>

                    {{ indicador.text | toUpperCase }}
                  </td>
                  <td>

                    {{
                      itemSelected.indicadores[indicador.value] != null || itemSelected.indicadores[indicador.value] !=
                        undefined
                        ? itemSelected.indicadores[indicador.value]
                        : '-'
                    | parseNumberToFloatBrIgnoringNaN
                    }}
                    {{ 
                      ['psa', 'psfp'].includes(indicador.value) ? " %" : ""
                    }}
                  </td>
                  <td>
                    {{
                      itemSelected.metas[indicador.value] === undefined
                        ? '-'
                        : itemSelected.metas[indicador.value]
                    | parseNumberToFloatBrIgnoringNaN
                    }}
                    {{ 
                      ['psa', 'psfp'].includes(indicador.value) ? " %" : ""
                    }}
                  </td>
                  <td>
                    <ArrowMetaDashboard :indicador="itemSelected.indicadores[indicador.value] != null || itemSelected.indicadores[indicador.value] != undefined
                      ? itemSelected.indicadores[indicador.value] : null"
                      :meta="itemSelected.metas[indicador.value] != undefined ? itemSelected.metas[indicador.value] : null" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="metasDialog = false">
            Fechar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </span>
</template>

<script>

import DashboardServicosService from "@/services/DashboardServicosService";
import ArrowMetaDashboard from "../dashboardLno/ArrowMetaDashboard.vue";
export default {
  name: "DashboardServicosGeral",
  components: { 'ArrowMetaDashboard': ArrowMetaDashboard },
  props: {
    competenciaDe: {
      type: String,
      required: true,
    },
    competenciaAte: {
      type: String,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    servicoSelecionado: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    traducaoIndicadores:{
      'pendentes_em_atraso':'Este campo exibe o valor correspondente à data mais recente inserida dentro do intervalo especificado',
      'pendentes':'Este campo exibe o valor correspondente à data mais recente inserida dentro do intervalo especificado',
      'psa':'Percentual de Serviços em Atraso (Pendentes) <br> Este campo exibe o valor correspondente à data mais recente inserida dentro do intervalo especificado',
      'pat': 'Prazo Médio de Atraso <br> Este campo exibe o valor correspondente à data mais recente inserida dentro do intervalo especificado',
      'patc':'Prazo de Atraso Serviços Realizados',
      'psfp': 'Percentual de Serviços Fora Prazo (Realizados)'
    },
    indicadores: [
      { text: "Pendentes", value: "pendentes" , align: ''},
      { text: "Pendentes em Atraso", value: "pendentes_em_atraso" , align: ''},
      { text: "PAT", value: "pat" , align: ''},
      { text: "Concluídos", value: "concluidos" , align: ''},
      { text: "Concluídos Fora Prazo", value: "concluidos_fora_prazo" , align: ''},
      { text: "PATC", value: "patc" , align: ''},
      { text: "Compensação", value: "compensacao" , align: ''},
      { text: "PSA", value: "psa" , align: ''},
      { text: "PSFP", value: "psfp" , align: ''},
    ],
    companies: [],
    dados: {},
    metasDialog: false,
    itemSelected: {
      indicadores: [],
      metas: [],
      competencia: '',
      empresa: {
        acronym: '',
        name: ''
      }
    }
  }),
  computed: {
    kpis() {
      
      return this.dados.length > 0 ? Object.keys(this.dados[0]) : [];
    },
    headers() {
      let headers = [];

      this.kpis.forEach(kpi => {
        let name = this.indicadores.find((element) => element.value == kpi)
        headers.push({
          text: name != undefined ? name.text.toUpperCase() : kpi.toUpperCase(),
          value: kpi.toLowerCase(),
          align: name != undefined ? name.align : 'start'
        });
      });
      console.log(headers)
      return headers;
    },
    items() {
      let items = [];
      if (this.dados.length > 0) {
        items = this.dados
      }
      return items;
    }
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      // Apenas atualiza os dados se for a visão selecionada
      if (this.activatedTab !== 0) return;

      this.$emit("updateLoadingDados", true);

      DashboardServicosService.getDadosIndicadoresTodasEmpresas(
        this.competenciaDe,
        this.competenciaAte,
        this.servicoSelecionado
      )
        .then(res => {
          this.dados = res;
        })
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados da visão Geral.', '', { position: 'topRight' })
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    classIndicador(indicador, meta) {
      let classes = [];

      if (indicador != null && meta != null) {
        if (indicador > meta) {
          classes.push('success--text font-weight-bold')
        } else if (indicador < meta) {
          classes.push('error--text font-weight-bold')
        }
      }
      return classes
    },
    openMetasDialog(linha) {
      this.itemSelected.indicadores = {
        'pendentes': linha.pendentes,
        'pendentes_em_atraso': linha.pendentes_em_atraso,
        'pat': linha.pat,
        'concluidos': linha.concluidos,
        'concluidos_fora_prazo': linha.concluidos_fora_prazo,
        'patc': linha.patc,
        'compensacao': linha.compensacao,
        'psa': linha.psa,
        'psfp': linha.psfp,
      };

      this.itemSelected.metas = linha.metas;
      this.itemSelected.empresa = linha.empresa;
      this.itemSelected.competencia = linha.competencia
      this.metasDialog = true;

    },
  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competenciaDe() {
      this.getDados();
    },
    competenciaAte() {
      this.getDados();
    },
    servicoSelecionado() {
      this.getDados();
    },
  }
}
</script>

<style>
.table-indicadores {
  margin: 10px 0;
  padding: 10px;
}

.table-indicadores table tbody tr td {
  font-size: 12px;
  padding: 0px 8px !important;
  text-align: end;
}

.table-indicadores table thead tr th {
  font-size: 12px;
  height: 25px;
  text-align: end !important;
  font-weight: 500;
}

.v-card-title-icon {
  position: relative;
  top: -40px;
  transition: .5s ease;
}
</style>