var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-data-table',{staticClass:"table-indicadores",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.items,"options":{ itemsPerPage: 10 },"headerProps":{ sortByText: 'Ordenar Por' },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [(_vm.traducaoIndicadores[h.value] != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.traducaoIndicadores[h.value])}})]):_c('span',[_vm._v(_vm._s(h.text))])]}}}),{key:"item.empresa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.empresa.name))+" ")]}},{key:"item.pendentes",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pendentes, item.metas.pendentes != undefined ? item.metas.pendentes : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pendentes))+" ")])]}},{key:"item.pendentes_em_atraso",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pendentes_em_atraso, item.metas.pendentes_em_atraso != undefined ? item.metas.pendentes_em_atraso : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pendentes_em_atraso))+" ")])]}},{key:"item.pat",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pat, item.metas.pat != undefined ? item.metas.pat : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pat))+" ")])]}},{key:"item.concluidos",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.concluidos, item.metas.concluidos != undefined ? item.metas.concluidos : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.concluidos))+" ")])]}},{key:"item.concluidos_fora_prazo",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.concluidos_fora_prazo, item.metas.concluidos_fora_prazo != undefined ? item.metas.concluidos_fora_prazo : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.concluidos_fora_prazo))+" ")])]}},{key:"item.patc",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.patc, item.metas.patc != undefined ? item.metas.patc : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.patc))+" ")])]}},{key:"item.compensacao",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.compensacao, item.metas.compensacao != undefined ? item.metas.compensacao : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.compensacao))+" ")])]}},{key:"item.psa",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.psa, item.metas.psa != undefined ? item.metas.psa : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.psa))+" % ")])]}},{key:"item.psfp",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.psfp, item.metas.psfp != undefined ? item.metas.psfp : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.psfp))+" % ")])]}},{key:"item.metas",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",attrs:{"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.openMetasDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"720"},model:{value:(_vm.metasDialog),callback:function ($$v) {_vm.metasDialog=$$v},expression:"metasDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('h4',[_vm._v(" "+_vm._s(_vm.itemSelected.empresa.name)+" - "+_vm._s(_vm.itemSelected.competencia)+" ")])]),_c('v-card-text',[_c('v-simple-table',{staticClass:"table-indicadores",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Indicador")]),_c('th',[_vm._v("Valor")]),_c('th',[_vm._v("Meta")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.indicadores),function(indicador){return _c('tr',{key:indicador.value},[(_vm.traducaoIndicadores[indicador.value] != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('td',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador.text))+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.traducaoIndicadores[indicador.value] )}})]):_c('td',[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador.text))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.itemSelected.indicadores[indicador.value] != null || _vm.itemSelected.indicadores[indicador.value] != undefined ? _vm.itemSelected.indicadores[indicador.value] : '-'))+" "+_vm._s(['psa', 'psfp'].includes(indicador.value) ? " %" : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.itemSelected.metas[indicador.value] === undefined ? '-' : _vm.itemSelected.metas[indicador.value]))+" "+_vm._s(['psa', 'psfp'].includes(indicador.value) ? " %" : "")+" ")]),_c('td',[_c('ArrowMetaDashboard',{attrs:{"indicador":_vm.itemSelected.indicadores[indicador.value] != null || _vm.itemSelected.indicadores[indicador.value] != undefined
                    ? _vm.itemSelected.indicadores[indicador.value] : null,"meta":_vm.itemSelected.metas[indicador.value] != undefined ? _vm.itemSelected.metas[indicador.value] : null}})],1)],1)}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.metasDialog = false}}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }